$(function () {
    'use strict';

    $(window).scroll(function () {
        var st = $(window).scrollTop();

        if ($("#carousel").length) {
            var wh = $("#carousel").height();
            setHeaderColor(st, wh);
        }

        if ($("#hero").length) {
            var wh = $("#hero").height();
            setHeaderColor(st, wh);
        }
    });

    $("#menu .fa-caret-down").on("click", function (e) {
        var submenu = $(this).siblings(".submenu");

        $('#menu .submenu').not(submenu).addClass('d-none');
        submenu.toggleClass("d-none");
    });

    $(document).on('click', function (e) {
        var container = $("#menu .has-submenu");
        // if the target of the click isn't the container nor a descendant of the container
        if (!container.is(e.target) && container.has(e.target).length === 0) {
            container.find(".submenu").addClass("d-none");
        }
    });

    $("#nav-toggle").click(function () {
        $(this).toggleClass("open");
        $("#menu").toggleClass("open");
    });

    setHeaderColor($(window).scrollTop(), window.innerHeight);
});

setHeaderColor = function (scrollTop, windowHeight) {
    if (!$("#menu").hasClass("open")) {
        if (scrollTop > windowHeight - 100) {
            if (!$("#logo").hasClass("invert")) {
                $("#logo").addClass("invert");
            }
            if (!$("#nav-toggle").hasClass("invert")) {
                $("#nav-toggle").addClass("invert");
            }
            if (!$("header").hasClass("fixed")) {
                $("header").addClass("fixed");
            }
        } else {
            if ($("#logo").hasClass("invert")) {
                $("#logo").removeClass("invert");
            }
            if ($("#nav-toggle").hasClass("invert")) {
                $("#nav-toggle").removeClass("invert");
            }
            if ($("header").hasClass("fixed")) {
                $("header").removeClass("fixed");
            }
        }
    }
};
